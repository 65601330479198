import { useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import img1 from './imgAhYo/01.jpg'
import img2 from './imgAhYo/02.jpg'
import img3 from './imgAhYo/03.jpg'
import img4 from './imgAhYo/04.jpg'
import img5 from './imgAhYo/05.jpg'
import img6 from './imgAhYo/06.jpg'
import img7 from './imgAhYo/07.jpg'
import img8 from './imgAhYo/08.jpg'
import img9 from './imgAhYo/09.jpg'
import { BsArrowRight } from 'react-icons/bs'

export default function AhYoGarden() {


    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    return (
        <div
            className="scroll-container"
            ref={scrollContainerRef}
            onWheel={handleScroll}
        >
            <div
                className="scroll-content"
                style={{ height: isMobile ? '59vh' : '65vh' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginLeft: '2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className="scroll">SCROLL</span> <BsArrowRight />
                    </div>
                </div>
                <div className="txtimg">
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img2}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                     <div className="txtimg">
                    <img
                        src={img4}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    </div>
                    <div className="projectText">
                    Sechs Architekturstudierende von fünf Universitäten, darunter von 
                    der TU Berlin, HCU Hamburg, HSRM Wiesbaden, HFT Stuttgart
                    und der NUS Singapore, fanden sich in Mae Sot, Thailand ein, um unter 
                    der Anleitung von Jan Glasmeier einen 
                    Schulerweiterungsbau zu planen und zu bauen. 
                    Dabei wurde nicht nur eigenverantwortlich vor Ort gearbeitet, 
                    sondern das Projekt auch durch eine selbstorganisierte Spendenkampagne 
                    teilfinanziert und innerhalb von fünf Wochen realisiert. 
                    <br />
                    <br />
                    Die politische Situation in Myanmar mit anhaltenden 
                    Konflikten führt zu großen Flüchtlingsbewegungen nach Thailand. 
                    In der Grenzstadt Mae Sot existieren mehrere sogenannte Migrant 
                    Learning Centers (MLC), die Bildung für Migranten- und 
                    Flüchtlingsgemeinschaften bieten. Die Schüler:innenzahlen aus 
                    Myanmar steigen aufgrund der verschärften Lage seit dem 
                    Militärputsch 2021 kontinuierlich, wodurch die Kapazitäten der MLC 
                    erschöpft sind und das Irrawaddy Flower Garden Learning Center zwei 
                    neue Klassenräume benötigt.
                    </div>
                    <div className="projectText">
                    Der kollaborative und partizipative Ansatz stand dabei
                    im Vordergrund des Design-Build-Projekts. 
                    Die Studierenden arbeiteten eng mit lokalen Arbeitern zusammen, 
                    bildeten so ein interdisziplinäres Team und bezogen auch 
                    die Kinder und Lehrkräfte der Schule in den Planungs- und 
                    Bauprozess mit ein. Sprachliche Barrieren taten der interkulturellen 
                    Zusammenarbeit keinen Abbruch, sondern riefen zum Ideenreichtum auf. 
                    In einem produktiven Miteinander wurde gemeinsam geplant, 
                    gebaut und voneinander gelernt.
                    <br />
                    <br />
                    Mit einem geringen Budget legte das Projekt 
                    besonderen Fokus auf kosteneffizientes Bauen mit 
                    recycelten und lokalen Materialien, in einer einfachen, 
                    potenziell rückbaubaren Bauweise. 
                    Das Besondere: Es gab keinen Entwurf. 
                    Erst nach der Ankunft vor Ort wurde mit dem Entwerfen 
                    begonnen, nach der ersten Grundstücksbegehung.
                    </div>
                    <div className="projectText">
                    Nach dem Motto "Schritt für Schritt" 
                    und "Learning by doing" stellten sich die Studierenden dem Bauvorhaben. 
                    Dabei waren die Herausforderungen vor Ort vielseitig. 
                    Der Bauprozess erforderte hohe Flexibilität und Anpassungsfähigkeit, 
                    da unvorhergesehene Hindernisse wie Ungenauigkeiten bei den Materialien, 
                    wie dem recycelten Holz, das aus dem lokalen Woodshop bezogen wurde, 
                    fehlende Werkzeuge und weitere Überraschungen im Prozess auf 
                    die Studierenden warteten.
                    <br />
                    <br />
                    Mit einem geringen Budget legte das Projekt 
                    besonderen Fokus auf kosteneffizientes Bauen mit 
                    recycelten und lokalen Materialien, in einer einfachen, 
                    potenziell rückbaubaren Bauweise. 
                    Das Besondere: Es gab keinen Entwurf. 
                    Erst nach der Ankunft vor Ort wurde mit dem Entwerfen 
                    begonnen, nach der ersten Grundstücksbegehung.
                    </div>
                    <div className="projectText">
                    Das Schulgebäude besteht aus einer luftigen, 
                    offenen Holzrahmenkonstruktion mit einem tiefgezogenen Dach. 
                    Dadurch kann auf zusätzliche Wände verzichtet werden, und die 
                    Windzirkulation wird als passive Kühlungsmethode genutzt. 
                    Der Grundriss wurde unter Berücksichtigung des Standortkontextes 
                    entwickelt, wobei die Eingänge zu den beiden neuen Klassenzimmern 
                    paarweise in der Mitte des Riegels platziert sind. 
                    Eine einzige raumdefinierende Wand, errichtet aus 
                    selbsthergestellten Lehmziegeln mit lokaler Erde, 
                    unterteilt die beiden Klassenräume räumlich. 
                    Aus der Rahmenkonstruktion wurden Sitzbänke und Regale entwickelt. 
                    Für eine optimale Raumnutzung sind die Tafeln durch einen 
                    Einschub zurückversetzt. Die Frontfassaden wurden aus Reststücken 
                    der Dachlattung gebaut, und der Boden wurde mit lokalen gebrannten Ziegeln 
                    gelegt, wobei diese durch einen umlaufenden Randstreifen aus 
                    Beton lediglich eingeklopft werden konnten. 
                    Durch die Fundamentwahl konnte das Projekt mit 
                    lediglich 2m3 Beton auskommen.

                    </div>
                    <div className="projectText">
                    Der Bauprozess zeigte, 
                    dass das Planen und Bauen im Design-Build-Format, 
                    insbesondere in einer solchen Region, es erfordert, 
                    sich den Gegebenheiten vor Ort anzunehmen und 
                    im Moment zu entwerfen, um kreative Lösungsansätze zu finden. 
                    Diese Erfahrung zeigte den Studierenden auch, wie wichtig es ist, 
                    flexibel zu bleiben, und welche Stärke in der Zusammenarbeit im Team besteht. 
                    Zusätzlich bot das Projekt die Möglichkeit für die Studierenden, 
                    sich abseits von DIN-Normen in ihrem Arbeiten zu erproben und auszuprobieren. 
                    So konnten während des Projekts nicht nur praktische Erfahrungen 
                    gesammelt werden, sondern im interkulturellen Austausch wurden auch 
                    wichtige zwischenmenschliche Werte transportiert.
                    <br />
                    Das Projekt wurde zu Beginn des Jahres, zwischen Januar und Februar 2024, 
                    innerhalb von nur fünf Wochen geplant und realisiert. 
                    <br />
                    <br />
                    Ein Text von Jonathan Wiedemann
                    </div>
                    <div className="projectText">
                    <p style={{textDecoration:'underline', fontSize: '16px', fontWeight: 'bold'}}>Freiwillige:</p>
                    
                    Támi Forgó (HCU Hamburg), Vincent Georg, 
                    Elisa Bisek (HSRM Wiesbaden), 
                    Leo Huang (NUS Singapore), Buesra Kuepeli (HFT Stuttgart), 
                    Jonathan Wiedemann (TU Berlin)
                    <br />                    
                    <br />
                    Info: Die Zeichnungen wurden von Jonathan Wiedemann erstellt, 
                    die Bildcredits sind den Bilddateien beigefügt. 
                    </div>
                    <img
                        src={img3}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div className="txtimg">
                    <img
                        src={img5}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img6}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img7}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img8}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img9}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div style={{ color: 'white' }}>hidden text</div>
                </div>
            </div>
        </div>
    )
}
