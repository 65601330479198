import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import NewDay from './projects/newDay'

export function ProjectNewDayComponent() {
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">NEW DAY SCHOOL</div>
                <div className="info_wrapper">
                    <div className="info">
                        {/* TODO: Media break for mobile devices */}
                        <span className="infoWhere">WO:</span>
                        <span className="infoWhat">MAE SOT, THAILAND</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WAS:</span>
                        <span className="infoWhat">SCHULE</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WANN:</span>
                        <span className="infoWhat">2024 - </span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">FÜR WEN:</span>
                        <span className="infoWhat">HELP WITHOUT FRONTIERS</span>
                    </div>
                </div>
            </div>
            <hr />
            <NewDay />
            <hr />
        </TabsContainer>
    )
}
