import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import AhYoGarden from './projects/ahYoO'

export function ProjectAhYoneOComponent() {
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">IRRAWADDY FLOWER GARDEN</div>
                <div className="info_wrapper">
                    <div className="info">
                        {/* TODO: Media break for mobile devices */}
                        <span className="infoWhere">WO:</span>
                        <span className="infoWhat">MAE SOT, THAILAND</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WAS:</span>
                        <span className="infoWhat">SCHULE</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WANN:</span>
                        <span className="infoWhat">2024 - </span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">FÜR WEN:</span>
                        <span className="infoWhat">HELP WITHOUT FRONTIERS</span>
                    </div>
                </div>
            </div>
            <hr />
            <AhYoGarden />
            <hr />
        </TabsContainer>
    )
}
