import { useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import img1 from './imgNewDay/01.jpg'
import img2 from './imgNewDay/02.jpg'
import img3 from './imgNewDay/03.jpg'
import img4 from './imgNewDay/04.jpg'
import img5 from './imgNewDay/05.jpg'
import img6 from './imgNewDay/06.jpg'
import img7 from './imgNewDay/07.jpg'
import img8 from './imgNewDay/08.jpg'
import img9 from './imgNewDay/09.jpg'
import img10 from './imgNewDay/10.jpg'
import img12 from './imgNewDay/12.jpg'
import { BsArrowRight } from 'react-icons/bs'

export default function NewDay() {
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    return (
        <div
            className="scroll-container"
            ref={scrollContainerRef}
            onWheel={handleScroll}
        >
            <div
                className="scroll-content"
                style={{ height: isMobile ? '59vh' : '65vh' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginLeft: '2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className="scroll">SCROLL</span> <BsArrowRight />
                    </div>
                </div>
                <div className="txtimg">
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img2}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    /> 
                    <div className="projectText">
                    Anfang 2024 haben wir die NEW DAY SCHOOL 
                    durch den Bau von vier neuen Klassenräume unterstützt. 
                    Dieses Projekt wurde innerhalb von sechs Wochen umgesetzt 
                    und ermöglicht durch den Einsatz eines großartigen Teams 
                    und der Unterstützung der lokalen Gemeinschaft.
                    <br />
                    Dieses Projekt entstand im Rahmen der Hands 
                    On Initiative der Stiftung deutscher Architekten 
                    in Kooperation mit derArchitektenkammer NRW und simple architecture.
                    </div>
                    <img
                        src={img3}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div className="txtimg">
                    <img
                        src={img4}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img5}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div className="txtimg">
                    <img
                        src={img6}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img7}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img8}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img9}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img10}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img12}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div style={{ color: 'white' }}>hidden text</div>
                </div>
            </div>
        </div>
    )
}
